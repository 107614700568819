<template>
  <div class="page left">
    <div class="left-panel top-indent">
      <div class="left-panel__inner">
        <div class="mobile ai-left">
          <div class="ai-left__title text-title">
            {{ tab }}
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="$emit('input', false)"
            >
              <path
                d="M12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41L12.59 0Z"
              />
            </svg>
          </div>
          <div class="ai-left__search">
            <AInput v-model="search" />
          </div>
        </div>
        <div class="desktop">
          <form class="left-panel__form">
            <input v-model="search" type="text" class="left-panel__input" />
            <button class="left-panel__btn" @click.prevent="searchClick">Найти</button>
          </form>
          <div class="left-panel__form-buttons">
            <button
              v-for="item in tabList"
              :key="item"
              :class="['left-panel__btn button', { active: tab === item }]"
              @click="clickTab(item)"
            >
              {{ item }}
            </button>
          </div>
        </div>
        <div v-if="filter.length > 0">
          <ul class="left-panel__menu">
            <li :class="['left-panel__menu-item', { active: !selected }]" @click="selectAll">
              <a class="left-panel__menu-link" href="#">Все AI</a>
            </li>
            <li
              :class="['left-panel__menu-item', { active: selected === list }]"
              v-for="(list, i) in filter"
              :key="list + 'list' + i"
              @click="click(list)"
            >
              <a class="left-panel__menu-link" href="#">{{ list }}</a>
            </li>
          </ul>
        </div>
        <div class="no-content" v-else>{{ loading ? 'Загрузка...' : 'Проекты не найдены' }}</div>
      </div>
    </div>
  </div>
</template>
<script>
//  @click="handleChangeMain(list), scrollProject(list.name)"
import AInput from '@/components/forms/AInput';
import { mapGetters } from 'vuex';
export default {
  name: 'leftPanel',
  props: {
    loading: Boolean
  },
  components: {
    AInput,
  },
  data() {
    return {
      search: '',
      tabList: ['Ниши', 'Темы'],
    };
  },
  computed: {
    ...mapGetters({
      tags: 'getTags',
      category: 'getCategory',
    }),
    selected: {
      set(value) {
        this.$store.dispatch('setSelected', value);
      },
      get() {
        return this.$store.getters['getSelected'];
      },
    },
    tab: {
      set(value) {
        this.$store.dispatch('setTab', value);
      },
      get() {
        return this.$store.getters['getTab'];
      },
    },
    filter() {
      return [...this.sort(this.tab === 'Ниши' ? this.tags : this.category)].filter(i =>
        i.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    click(item) {
      this.selected = item;
      this.$emit('select', item);
    },
    selectAll() {
      this.selected = '';
      this.$emit('select', '');
    },
    clickTab(item) {
      this.tab = item;
      this.selected = '';
      this.search = '';
      this.$emit('select', '');
    },
    sort(arr) {
      const sort = [];
      const obj = arr.reduce((acc, item) => {
        if (!acc[item]) acc[item] = 1;
        else acc[item] = acc[item] + 1;
        return acc;
      }, {});
      for (let key in obj) {
        sort.push([`${key}`, obj[key]]);
      }
      sort.sort((a, b) => a[1] - b[1]).reverse();
      return sort.map(i => i[0]);
    },
  },
};
</script>
