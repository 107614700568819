import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios';

const API_KEY = 'AIzaSyAkZV_TWiVy9cNlVgxzH8jV7p2AvqLQ4NQ'
const SHEET = '1-Q_0lKVf-yVwnEvAZoc_S-MkEuMe1pbVerR8dfn3X2E'
// import csv from '../../tablet/terra'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    search: '',
    url: `https://sheets.googleapis.com/v4/spreadsheets/${SHEET}?key=${API_KEY}&includeGridData=true`,
    sheets: [],
    tab: 'Ниши',
    selected: '',
    loading: true
  },
  mutations: {
    updateSearch (state, text) {
      state.search = text;
    },
    SET_SHEETS (state, value) {
      state.sheets = value;
    },
    SET_TAB (state, value) {
      state.tab = value;
    },
    SET_SELECTED (state, value) {
      state.selected = value;
    },
    SET_LOADING (state, value) {
      state.loading = value;
    },
  },
  actions: {
    async get ({ state: { url }, commit }) {
      try {
        const { data } = await axios.get(url)
        let res = data?.sheets?.[0]?.data?.[0]?.rowData || [];
        // console.log(res)
        const arr = res.map((item, i) => {
          if ((item.values.length > 8, i)) {
            const value = item.values;
            const tags = (value?.[1]?.userEnteredValue?.stringValue || '').trim().split(',')
            const category = (value?.[2]?.userEnteredValue?.stringValue || '').trim().split(',')
            const name = (value?.[3]?.userEnteredValue?.stringValue || '').trim()
            const description = (value?.[4]?.userEnteredValue?.stringValue || '').trim()
            const image = (value?.[7]?.userEnteredValue?.stringValue || '').trim()
            const price = (value?.[5]?.userEnteredValue?.stringValue || '').trim()
            const deadline = (value?.[6]?.userEnteredValue?.stringValue || '').trim()
            const link = (value?.[8]?.userEnteredValue?.stringValue || '').trim()
            const profit = (value?.[9]?.userEnteredValue?.stringValue || '').trim()

            return name ? {
              tags,
              category,
              name,
              description,
              image,
              price,
              deadline,
              link,
              profit,
            } : null;
          } {
            return null
          }
        }).filter(i => i)
        commit('SET_LOADING', false)
        commit('SET_SHEETS', arr)
        // console.log(arr)
      } catch (error) {
        console.log(error)
      }
    },
    setTab ({ commit }, value) {
      commit('SET_TAB', value)
    },
    setSelected ({ commit }, value) {
      commit('SET_SELECTED', value)
    }
  },
  getters: {
    getLoading: ({ loading }) => loading,
    getTab: ({ tab }) => tab,
    getSelected: ({ selected }) => selected,
    getSheets: ({ sheets }) => sheets,
    getTags: ({ sheets }) => sheets.map(item => item.tags).filter(i => i?.[0]).flat().map(i => i.trim()),
    getCategory: ({ sheets }) => sheets.map(item => item.category).filter(i => i?.[0]).flat().map(i => i.trim()),
  }
});
