<template>
  <div id="app">
    <Header v-model="isActive" />
    <div class="body">
      <LeftPanel v-model="isActive" :class="{ active: isActive }" :loading="loading" @select="select" />
      <Main
        v-model="isActive"
        :sheets="filteredList"
        :selected="selected"
        :loading="loading"
        @select="(selected = $event), scrollProject($event.name)"
        @type="changeType"
      />
      {{ loading }}
      <RightPanel v-bind="selected" :loading="loading" @back="selected = {}" />
    </div>
    <!-- <div class="mobile scrollup" @click="scrollToTop">
      <i class="fa fa-chevron-up"></i>
    </div> -->
  </div>
</template>
<script>
import Header from '@/components/Header.vue';
import LeftPanel from '@/components/LeftPanel.vue';
import RightPanel from '@/components/RightPanel.vue';
import Main from '@/components/Main.vue';
// import store from './store/index';
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    Header,
    LeftPanel,
    RightPanel,
    Main,
  },
  // store,
  data: () => ({
    search: '',
    selected: {},
    isActive: false,
  }),
  computed: {
    ...mapGetters({
      sheets: 'getSheets',
      loading: 'getLoading',
    }),
    show() {
      return Boolean(this.selected?.name || '');
    },
    filteredList() {
      if (!this.search) return this.sheets;
      const search = this.search.toLowerCase();
      return this.sheets.filter(x => {
        return x.tags.find(i => i.toLowerCase() === search) || x.category.find(i => i.toLowerCase() === search);
      });
    },
  },
  methods: {
    scrollToTop() {
      console.log('sdsdsdsd');
      this.$nextTick(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      });
    },
    changeType(e) {
      console.log(e);
    },
    click() {
      this.selected = {};
    },
    select(item) {
      console.log(item);
      this.isActive = false;
      this.search = item;
      this.selected = {};
    },
    scrollProject(item) {
      document.getElementById(item).scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    },
  },
  async mounted() {
    const theme = localStorage.getItem('theme') === 'dark';
    document.documentElement.setAttribute('theme', theme ? 'dark' : 'light');
    document.querySelector('meta[name="theme-color"]').setAttribute('content', theme ? '#17212b' : '#FFFFFF');
    document
      .querySelector('meta[name="apple-mobile-web-app-status-bar-style"]')
      .setAttribute('content', theme ? '#17212b' : '#FFFFFF');
    await this.$store.dispatch('get');
  },
};
</script>
