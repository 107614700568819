<template>
  <div class="a-input">
    <svg class="a-input__search" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9306 13.6461C12.7353 13.8413 12.4188 13.8413 12.2235 13.6461L8.09846 9.52193C7.93057 9.35407 7.66879 9.33 7.46077 9.44443C5.35128 10.6049 2.68433 10.1097 1.13737 8.21513C-0.496313 6.21427 -0.350859 3.30171 1.47405 1.47358C3.30186 -0.35203 6.21475 -0.498113 8.21604 1.13546C10.1111 2.68234 10.6065 5.34965 9.44585 7.45938C9.33144 7.66736 9.35548 7.92909 9.52328 8.09699L13.6467 12.223C13.8419 12.4183 13.8418 12.7348 13.6466 12.93L12.9306 13.6461ZM5.03405 2.01357C3.5933 2.01266 2.35253 3.0296 2.07053 4.44248C1.78854 5.85537 2.54386 7.2707 3.87457 7.82293C5.20529 8.37516 6.74079 7.91049 7.54203 6.71309C8.34328 5.51569 8.18711 3.91904 7.16905 2.89958C6.6043 2.33092 5.83549 2.01187 5.03405 2.01357Z"
        fill="#A1AFBC"
      />
    </svg>
    <input v-model="search" type="text" placeholder="Найти" />
    <svg v-if="search" class="a-input__clear"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      @click="search = ''"
    >
      <path d="M12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41L12.59 0Z" />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    search: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      },
    },
  },
};
</script>

<style lang="scss">
.a-input {
  position: relative;
  width: 100%;
  &__search {
    position: absolute;
    top: 13px;
    left: 10px;
  }
  &__clear {
    position: absolute;
    top: 13px;
    right: 10px;
    fill: #A7BED3;
  }
  input {
    width: 100%;
    height: 40px;
    background-color: var(--app-background-scroll-color);
    color: var(--color-text);
    border: 1px solid #65b9f4;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 34px 0 34px;
    // box-shadow: inset 0px 1px 7px rgba(90, 200, 250, 0.3);
    border-radius: 4px;
    &:hover {
      border: 1px solid #65b9f4;
      // box-shadow: inset 0px 1px 7px rgba(90, 200, 250, 0.3);
    }
    &:focus-visible {
      outline: none;
      border: 1px solid #65b9f4;
      // box-shadow: inset 0px 1px 7px rgba(90, 200, 250, 0.3);
    }
  }
}
</style>