<template>
  <div class="page center">
    <div class="main top-indent">
      <div v-if="sheets.length > 0" class="main__inner">
        <div class="">
          <h2 class="main-title desktop">{{ selectedTag ? selectedTag : 'Все проекты' }}</h2>
        </div>
        <div class="mobile ai-main">
          <div class="ai-main__search">
            <AInput v-model="search" />
          </div>

          <div class="ai-main__title text-title">{{ selectedTag ? selectedTag : 'Все проекты' }}</div>
          <div class="ai-main__type">
            <div class="text-subtitle" @click="active('Ниши')">
              <svg width="15" height="6" viewBox="0 0 15 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 5H15V6H0V5Z" fill="#A7BED3" />
                <path d="M0 0H15V1H0V0Z" fill="#A7BED3" />
              </svg>
              Ниши
            </div>
            <div class="text-subtitle" @click="active('Темы')">
              <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 4H15V5H0V4Z" fill="#A7BED3" />
                <path d="M0 8H9V9H0V8Z" fill="#A7BED3" />
                <path d="M0 0H15V1H0V0Z" fill="#A7BED3" />
              </svg>
              Темы
            </div>
          </div>
        </div>

        <div
          v-for="(item, i) in filter"
          :ref="item.name"
          :id="item.name"
          :key="item.name + i"
          :class="selected.name === item.name ? 'main__item active active-project' : 'main__item'"
          @click="click(item)"
        >
          <div v-if="item.image" class="main__image">
            <div :style="{ background: 'url(' + item.image + ')' }" class="main__image-background"></div>
          </div>
          <div v-else class="main__no-image">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M39.5 35.1667V4.83333C39.5 2.45 37.55 0.5 35.1667 0.5H4.83333C2.45 0.5 0.5 2.45 0.5 4.83333V35.1667C0.5 37.55 2.45 39.5 4.83333 39.5H35.1667C37.55 39.5 39.5 37.55 39.5 35.1667ZM13.2833 24.29L17.8333 29.7717L24.55 21.1267C24.9833 20.5633 25.85 20.5633 26.2833 21.1483L33.8883 31.2883C34.43 32.0033 33.91 33.0217 33.0217 33.0217H7.04333C6.13333 33.0217 5.635 31.9817 6.19833 31.2667L11.5933 24.3333C12.005 23.77 12.8283 23.7483 13.2833 24.29Z"
                fill="#A7BED3"
              />
            </svg>
          </div>
          <div class="main__item-bottom">
            <p class="main__title">{{ item.name }}</p>
            <div class="main__tags">
              <div v-for="tag in tags(item)" :key="tag" class="main__tag">
                {{ tag }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="no-content" v-else>{{ loading ? 'Загрузка...' : 'Проекты не найдены' }}</div>
    </div>
  </div>
</template>
<script>
import AInput from '@/components/forms/AInput';
export default {
  name: 'ai-main',
  components: {
    AInput,
  },
  props: {
    value: Boolean,
    sheets: Array,
    loading: Boolean,
    selected: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    search: '',
  }),
  computed: {
    tab: {
      set(value) {
        this.$store.dispatch('setTab', value);
      },
      get() {
        return this.$store.getters['getTab'];
      },
    },
    selectedTag: {
      set(value) {
        this.$store.dispatch('setSelected', value);
      },
      get() {
        return this.$store.getters['getSelected'];
      },
    },
    filter() {
      return this.sheets.filter(i => i.name.toLowerCase().includes(this.search.toLowerCase()));
    },
  },
  methods: {
    active(value) {
      this.tab = value;
      this.$emit('input', !this.value);
    },
    click(item) {
      this.$emit('select', item);
    },
    tags({ tags }) {
      tags = tags || '';
      // console.log(tags);
      return tags; //.split(',');
    },
  },
};
</script>
