import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import '@/assets/scss/styles.scss'
// import vuescroll from '@/assets/js/vuescroll-native.min.js';

// Vue.use(vuescroll, {
//   ops: {
//     vuescroll: {
//       mode: 'native',
//       sizeStrategy: 'percent',
//       detectResize: false,
//       locking: true,
//     },
//     bar: {
//       showDelay: 500,
//       onlyShowBarOnScroll: false,
//       keepShow: true,
//       background: '#fff',
//       opacity: 1,
//       hoverStyle: false,
//       specifyBorderRadius: false,
//       minSize: 0,
//       size: '4px',
//       disable: false
//     },
//     rail: {
//       gutterOfEnds: '3px',
//       gutterOfSide: '3px',
//       background: '#fff',
//       opacity: 0,
//       size: '4px',
//     }
//   },
//   name: 'scrollbar'
// });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
