<template>
  <div :class="name ? 'page right active' : 'page right'">
    <div class="mobile ai-right">
      <div class="ai-right__title" @click="$emit('back', '')">
        <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.5 0.5L0 7L6.5 13.5L7.207 12.793L1.914 7.5H24V6.5H1.914L7.207 1.207L6.5 0.5Z" fill="#A7BED3" />
        </svg>
        <span class="text-title">{{ selectedTag ? selectedTag : 'Все AI' }}</span>
      </div>
    </div>
    <div class="right-panel top-indent">
      <div v-if="name" class="right-panel__inner">
        <h2 class="right-panel__title">{{ name }}</h2>

        <div class="right-panel__photo">
          <div v-if="image" :style="{ background: 'url(' + image + ')' }" class="right-panel__photo-background"></div>
          <div v-else class="main__no-image">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M39.5 35.1667V4.83333C39.5 2.45 37.55 0.5 35.1667 0.5H4.83333C2.45 0.5 0.5 2.45 0.5 4.83333V35.1667C0.5 37.55 2.45 39.5 4.83333 39.5H35.1667C37.55 39.5 39.5 37.55 39.5 35.1667ZM13.2833 24.29L17.8333 29.7717L24.55 21.1267C24.9833 20.5633 25.85 20.5633 26.2833 21.1483L33.8883 31.2883C34.43 32.0033 33.91 33.0217 33.0217 33.0217H7.04333C6.13333 33.0217 5.635 31.9817 6.19833 31.2667L11.5933 24.3333C12.005 23.77 12.8283 23.7483 13.2833 24.29Z"
                fill="#A7BED3"
              />
            </svg>
          </div>
        </div>
        <div class="right-panel__content">
          <div class="right-panel__content-top">
            <h4 class="right-panel__content-title">Описание проекта</h4>
            <a v-if="link" class="right-panel__content-link" :href="link" target="_blank">Открыть проект</a>
          </div>
          <div class="right-panel__text" v-html="description"></div>

          <div v-if="profit" class="right-panel__profit">
            <div class="right-panel__profit-title">Выгоды для компании</div>
            <ul class="right-panel__profit-list">
              <li v-for="item in getProfit" :key="item">
                {{ item }}
              </li>
            </ul>
          </div>
          <!-- <div v-if="deadline || price" class="right-panel__price-timing price-timing">
            <div class="price-timing__title">Стоимость и сроки</div>
            <div class="price-timing__inner">
              <div v-if="price" class="right-panel__price">
                <svg class="icon-price" width="20" height="26" viewBox="0 0 20 26" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 25.5C4.58 25.5 0 22.9562 0 19.945V6.055C0 3.04375 4.58 0.5 10 0.5C15.42 0.5 20 3.04375 20 6.055V19.945C20 22.9562 15.4212 25.5 10 25.5ZM2.5 16.625V19.9487C2.5875 20.64 5.38625 22.7262 10 22.7262C14.6138 22.7262 17.4137 20.6338 17.5 19.9412V16.625C15.2243 17.9442 12.6299 18.6131 10 18.5588C7.37008 18.6132 4.77572 17.9443 2.5 16.625ZM2.5 9.67625V13C2.5875 13.6912 5.38625 15.7775 10 15.7775C14.6138 15.7775 17.4137 13.685 17.5 12.9925V9.67625C15.2244 10.996 12.63 11.6653 10 11.6112C7.36996 11.6654 4.77557 10.996 2.5 9.67625ZM10 3.2775C5.385 3.2775 2.58625 5.37 2.5 6.06375C2.5875 6.75 5.38875 8.8325 10 8.8325C14.6112 8.8325 17.4137 6.74 17.5 6.0475C17.4125 5.35875 14.6112 3.2775 10 3.2775Z"
                  />
                </svg>

                <div class="right-panel__price-title">Ориентировочная стоимость</div>
                <div class="price-timing__text">
                  {{ price }}
                </div>
              </div>

              <div v-if="deadline" class="right-panel__timing">
                <svg class="icon-timing" width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13 25.5C6.09644 25.5 0.5 19.9036 0.5 13C0.5 6.09644 6.09644 0.5 13 0.5C19.9036 0.5 25.5 6.09644 25.5 13C25.4924 19.9004 19.9004 25.4924 13 25.5ZM13 3C7.47715 3 3 7.47715 3 13C3 18.5228 7.47715 23 13 23C18.5228 23 23 18.5228 23 13C22.9938 7.47972 18.5203 3.0062 13 3ZM19.25 13.25C19.25 13.8023 18.8023 14.25 18.25 14.25H12.75C12.1977 14.25 11.75 13.8023 11.75 13.25V7.75C11.75 7.19772 12.1977 6.75 12.75 6.75H13.25C13.8023 6.75 14.25 7.19772 14.25 7.75V10.75C14.25 11.3023 14.6977 11.75 15.25 11.75H18.25C18.8023 11.75 19.25 12.1977 19.25 12.75V13.25Z"
                  />
                </svg>

                <div class="right-panel__timing-title">Ориентировочные сроки</div>
                <div class="price-timing__text">
                  {{ deadline }}
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div v-else class="text-select-card">{{ loading ? 'Загрузка...' : 'Выберите карточку' }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'right-panel',
  props: {
    loading: Boolean,
    tags: {
      typy: String,
      default: '',
    },
    category: {
      typy: String,
      default: '',
    },
    name: {
      typy: String,
      default: '',
    },
    description: {
      typy: String,
      default: '',
    },
    image: {
      typy: String,
      default: '',
    },
    price: {
      typy: String,
      default: '',
    },
    deadline: {
      typy: String,
      default: '',
    },
    link: {
      typy: String,
      default: '',
    },
    profit: {
      typy: String,
      default: '',
    },
  },
  data: () => ({}),
  computed: {
    selectedTag: {
      set(value) {
        this.$store.dispatch('setSelected', value);
      },
      get() {
        return this.$store.getters['getSelected'];
      },
    },
    getProfit() {
      return this.profit ? this.profit.split(',') : [];
    },
  },
};
</script>
